html {
	color: #000 !important;
	font-size: 13.5pt;
}

body {
	background: #fff !important;;
}

.header {
	position: static !important;;
	width: 100% !important;;

}

.nav__mobile, .nav {
	display: none !important;;
}

.header__contactdetails {
	color: #000 !important;;

	img {
		display: none !important;;
	}

	span {
		display: block;
	}
}

.footer, form {
	display: none !important;
}

.b-grid {
	margin: 0 !important;

	&__wrap {
		display: block;
	}

	&__cell {
		page-break-inside: avoid;

		&--1of2 {
			float: left;
		}
	}

}

.person {
	width: 200px !important;

	.button {
		font-size: 12pt;
	}

	&__name {
		display: block !important;
		padding: 0 !important;
	}

	&__photo {
		width: 33% !important;
	}
}

.button {
	padding: 0 !important;
}

a[href^=mailto]::after {
	content: attr(href);
}

h1, h2, h3, h4, h5, h6 {
	page-break-after: avoid;
}